import React from 'react';
const BreadcrumbLine = () => (
  <svg
    width="17px"
    height="50px"
    viewBox="0 0 17 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Line</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Addons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g id="Homework" transform="translate(-373.000000, -69.000000)" stroke="#DADADA">
        <g id="Controls/Navbar" transform="translate(30.000000, 69.000000)">
          <g id="MISC/NavBarSeparator" transform="translate(344.000000, 0.000000)">
            <polyline id="Line" points="0.347222222 0 15.1565521 25 0.347222222 50" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BreadcrumbLine;
