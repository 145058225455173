import React from 'react';
import classNames from 'classnames';
import './ValidationError.scss';

interface Props {
  className?: string;
  message?: string | JSX.Element;
}

export default class ValidationError extends React.PureComponent<Props> {
  public render() {
    const {message, className} = this.props;
    if (message === undefined || (typeof message === 'string' && message.length === 0)) {
      return null;
    }

    const classes = classNames('xeditor-validation-error', className);

    return <div className={classes}>{message}</div>;
  }
}
