import React, {type FC, memo, useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import Icon from 'components/Icon';

import './ImageInput.scss';

interface Props {
  id: string;
  imageId?: number;
  widgetId?: string;
  handle?: JSX.Element;
  isDragLayer?: boolean;
  isCenterDeleteButton?: boolean;
  storeFile: (file: File) => void;
  deletePair?: () => void;
  handleFileSelected: (imageDataUrl: string) => void;
}
type ItemObject = {files: File[]};

export const ImageInput: FC<Props> = memo(
  ({
    id,
    imageId,
    handleFileSelected,
    storeFile,
    deletePair,
    handle,
    isDragLayer,
    isCenterDeleteButton = false
  }) => {
    const fileInput = useRef<HTMLInputElement | null>(null);
    const fileReader = useRef(new FileReader());

    const handleFileDrop = useCallback(
      (item: ItemObject) => {
        const {files} = item;

        const [firstFile] = files;

        if (firstFile) {
          fileReader.current.readAsDataURL(firstFile);
        }

        storeFile(firstFile);
      },
      [fileReader, storeFile]
    );
    const [{isOver}, drop] = useDrop({
      accept: NativeTypes.FILE,
      collect: m => ({isOver: m.isOver()}),
      drop: (item: ItemObject) => handleFileDrop(item)
    });

    const handleFileInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.target['files'][0] && fileReader.current.readAsDataURL(e.target['files'][0]);
      if (fileInput.current?.files) {
        const files = fileInput.current.files;
        storeFile(files[0]);
      }
    };

    const imgUploadClasses = classNames(
      'img-upload',
      {'is-over': isOver},
      {'is-drag-layer': isDragLayer}
    );

    const deleteBtnClasses = classNames('delete-btn', {
      'with-image': !!imageId,
      center: isCenterDeleteButton
    });

    useEffect(() => {
      const reader = fileReader.current;
      const onLoadEnd = () => reader.result && handleFileSelected(reader.result as string);

      reader.addEventListener('loadend', onLoadEnd);

      return () => reader.removeEventListener('loadend', onLoadEnd);
    }, [handleFileSelected]);

    return (
      <div ref={drop} className={imgUploadClasses}>
        {imageId ? <LoadableImage imageId={imageId} width="100%" height="100%" /> : null}
        <div className="mask">
          {deletePair && (
            <Button className={deleteBtnClasses} onClick={deletePair}>
              <Icon name="trash" size="lg" />
            </Button>
          )}
          {handle && <div className="handle-wrapper">{handle}</div>}

          <label htmlFor={`image-upload-file-input-${id}`}>
            {imageId ? (
              <FormattedMessage id="XEditorWidget.ImageMatching.ReplaceImage" />
            ) : (
              <FormattedMessage
                id="XEditorWidget.ImageMatching.ImageUpload"
                values={{br: <br />}}
              />
            )}
          </label>
          <input
            ref={fileInput}
            accept={'.jpg,.jpeg,.png,.bmp'}
            id={`image-upload-file-input-${id}`}
            multiple={false}
            type="file"
            onChange={handleFileInput}
          />
        </div>
      </div>
    );
  }
);
