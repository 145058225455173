import React, {type FC} from 'react';
import {type CompleteCrop} from 'react-image-crop';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

import CropComponent from 'components/Crop';
import Icon from 'components/Icon';
import {type AspectRatio} from 'components/Crop/static';

interface Props {
  aspect: AspectRatio | number;
  minWidth: number;
  hide: () => void;
  imageDataUrl: string;
  renderFooter: () => JSX.Element;
  storeCrop: (crop: CompleteCrop) => void;
  setImageSize?: (imageSize: [number, number]) => void;
}

const CropModal: FC<Props> = ({
  aspect,
  hide,
  imageDataUrl,
  minWidth,
  renderFooter,
  storeCrop,
  setImageSize
}) => {
  const minHeight =
    aspect === 1 || typeof aspect !== 'number' ? minWidth : Math.ceil(minWidth / aspect);

  return (
    <Modal
      backdrop="static"
      className="image-cropper-modal dnd-image-modal"
      onHide={hide}
      show={true}
    >
      <Modal.Header className="dnd-image-modal-header">
        <FormattedMessage id="XEditorWidget.ImageMatching.Modal.Header" />
        <Button className="btn-xs" onClick={hide}>
          <Icon name="pc-close" />
        </Button>
      </Modal.Header>
      <Modal.Body className="dnd-image-modal-body">
        <div className="dnd-image">
          <CropComponent
            aspectRatio={aspect}
            getCrop={storeCrop}
            minHeight={minHeight}
            minWidth={minWidth}
            imageDataUrl={imageDataUrl}
            setImageSize={setImageSize}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="dnd-image-modal-footer">{renderFooter()}</Modal.Footer>
    </Modal>
  );
};

export default CropModal;
